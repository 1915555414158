<template>
    <div class="content-wrapper">
        <bo-page-title>
            <template slot="additionalCta">
                <b-button @click="apiGet()" class="btn bg-blue-400 btn-labeled btn-labeled-left ml-1">
                    <b><i class="icon-reload-alt"></i></b> Muat Ulang
                </b-button>
            </template>
        </bo-page-title>
        <!--icon-reload-alt-->
        <div class="content pt-0">
            <div class="form-row mb-2">
                <div class="col-md-6">
                    <div class="form-row">
                        <div class="col-md-12 mb-2">
                        <div class="box_call">
                            <div class="row align-items-center">
                            <div class="col-md-auto">
                                <div class="wrap_text">
                                <p>Tujuan</p>
                                <h3 v-if="Object.keys(unit||{}).length">{{unit.aru_unit_tujuan||"-"}}</h3>
                                <h3 v-else> - </h3>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-md-12" v-if="(resume.totalAntrean == 0 || !resume.totalSisa) && !resume.isTutup">
                            <div class="box_call bg_nonaktif" data-popup="tooltip"
                                title="" data-toggle="modal">
                                <div class="row align-items-center">
                                <div class="col-md-auto">
                                    <div class="wrap_text">
                                    <p class="text_light">Tidak ada Antrean</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-12" v-if="resume.isTutup">
                            <div class="box_call bg_call3" data-popup="tooltip"
                                title="" data-toggle="modal">
                                <div class="row align-items-center">
                                <div class="col-md-auto">
                                    <div class="wrap_text">
                                    <p class="text_light">Loket Admisi Tutup</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12" v-if="Object.keys(unit||{}).length && latestAntrean && resume.totalAntrean !== 0 && !resume.isTutup">
                            <div class="box_call bg_call1" @click="ambilAntrean()" style="cursor:pointer;" data-popup="tooltip"
                                title="" data-toggle="modal">
                                <div class="row align-items-center">
                                <div class="col-md-auto">
                                    <div class="ic_left">
                                    <i class="icon-megaphone"></i>
                                    </div>
                                </div>
                                <div class="col-md-auto">
                                    <div class="wrap_text">
                                    <p class="text_light">Panggil Antrean</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-row" id="antrian-today">
                        <div class="col-md-12">
                        <div class="card mb-2">
                            <div class="card-header">
                            <h5 class="card-title font-weight-semibold">Detail</h5>
                            </div>
                            <div class="table-responsive mb-0">
                            <table class="table  table-bordered table-striped">
                                <tbody>
                                <tr>
                                    <td>Total Antrean</td>
                                    <td class="table-info"><strong>{{resume.totalAntrean||0}}</strong></td>
                                </tr>
                                <tr>
                                    <td>Sudah dipanggil </td>
                                    <td class="table-success"><strong>{{resume.totalDone||0}}</strong></td>
                                </tr>
                                <tr>
                                    <td>Sisa Antrean </td>
                                    <td class="table-warning"><strong>{{resume.totalSisa||0}}</strong></td>
                                </tr>
                                <tr>
                                    <td>VOID</td>
                                    <td class="table-danger"><strong>{{resume.totalVoid||0}}</strong></td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                    <h5 class="card-title font-weight-semibold">Statistik </h5>
                    </div>
                    <div class="card-body">
                    <div class="chart-container">
                        <BarChart :chartData="dataAntreanStatistik"
                            :chartOptions="chartOptions"
                        />
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        
		<validation-observer ref="VFormAntreanAdmisi">
            <b-modal v-model="openAntrianAdmisi" :title="'Antrian Admisi'" size="sm" hide-footer>    
                <div class="text-center" v-if="openAntrianAdmisi">
                    <div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
                        <i class="icon-megaphone"></i>
                    </div>
                    <h6 class="mb-0">Memanggil Antrean</h6>
                    <h3 class="font-weight-semibold mb-0" style="font-size:36px;"> No.
                    {{latestAntrean.aadm_no_antrian||"-"}}</h3>
                    <small class="text-info mb-3">Pasien akan berubah status void setelah dipanggil 3x</small>
                    
                    <div v-if="latestAntrean.aadm_called > 3" class="form-group text-left mt-3" id="patientNotPresenceInfo">
                        <label for="patientNotPresent">Catatan Ketidakhadiran Pasien<strong class="text-danger">*</strong></label>
                        <textarea v-model="latestAntrean.aadm_notes" name="patientNotPresent" id="patientNotPresent" rows="3" class="form-control"
                        placeholder="cth. pasien tidak hadir setelah dipanggil 3x"></textarea>
                        
                        <VValidate :name="'Catatan'" v-model="latestAntrean.aadm_notes" :rules="{required: 1, min: 2}" />

                    </div>

                    <div v-if="latestAntrean.aadm_called > 3" class="mt-1">       
                        <a href="javascript:;" @click="voidPasien(latestAntrean)" class="btn btn-danger btn-labeled btn-labeled-left mr-1">
                        <b><i class="icon-checkmark"></i></b>VOID
                        </a>
                    </div>

                    <div v-else class="mt-1">

                        <a href="javascript:;" v-if="!btnPanggil" @click="panggilPasien(latestAntrean)" class="btn btn-warning btn-labeled btn-labeled-left">
                        <b><i class="icon-bell2"></i></b> Panggil Ulang
                        </a>
                        
                        <a href="javascript:;" v-else class="btn btn-secondary btn-labeled btn-labeled-left">
                        <b><i class="icon-bell2"></i></b> Memanggil ...
                        </a>
                    </div>
                    
                    <div class="mt-1">
                        <a href="javascript:;" @click="hadirPasien(latestAntrean)" class="btn btn-success btn-labeled btn-labeled-left mr-1">
                        <b><i class="icon-checkmark"></i></b>Hadir
                        </a>
                    </div>
                </div>
            </b-modal>
        </validation-observer>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
const moment = require('moment')
import $ from 'jquery'
import Gen from '@/libs/Gen.js'
import BarChart from '@/components/chart/BarChart'


export default {
    extends: GlobalVue,  
    data() {
        return {
            unit : {},
            latestAntrean : {},
            resume : {
                totalAntrean : 0,
                totalVoid : 0,
                totalDone : 0
            },
            openAntrianAdmisi: false,
            dataAntreanStatistik: {},
            chartOptions: {
                responsive: true
            },
            btnPanggil: false,
            isShow: false
        }
    },
    components:{
        BarChart
    },
    methods: {
        ambilAntrean(){
            this.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'take-antrian', id: this.latestAntrean.aadm_id}}, 
                "POST"
            ).then(()=>{
                this.$socket.emit('refresh_data', {to : 'Admisi'})
                this.$socket.emit('refresh_data_all',{})
                this.loadingOverlay = false
                this.openAntrianAdmisi = true
                this.isShow = false
                this.panggilPasien(this.latestAntrean)

            })
        },
        panggilPasien(antrean){
            this.btnPanggil = true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'panggil-pasien', id: antrean.aadm_id, aadm_called :  (this.latestAntrean.aadm_called + 1)}}, 
                "POST"
            ).then(()=>{
                antrean.to = 'Admisi'
                this.$socket.emit('panggil_pasien', antrean)
                this.latestAntrean.aadm_called = this.latestAntrean.aadm_called + 1    
                setTimeout(()=>{
                    this.btnPanggil = false
                },5000)     
                this.isShow = true
            }).catch(()=>{
                setTimeout(()=>{
                    this.btnPanggil = false
                },5000)     
            })
        },
        voidPasien(antrean){
            this.$refs['VFormAntreanAdmisi'].validate().then(success=>{
                if(success){
                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data:{type:'update-status', id: antrean.aadm_id, aadm_status : 'VOID'}}, 
                        "POST"
                    ).then(()=>{
                        this.openAntrianAdmisi = false
                        this.apiGet()
                        this.$socket.emit('refresh_data', {to : 'Admisi'})
                        this.$socket.emit('refresh_data_all',{})
                        return this.$swal({
                            icon: 'success',
                            title: 'Berhasil mengubah status antrean'
                        })
                    })
                }
            })
        },
        hadirPasien(antrean){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'update-status', id: antrean.aadm_id, aadm_status : 'DONE'}}, 
                "POST"
            ).then(()=>{
                this.openAntrianAdmisi = false
                this.apiGet()
                this.$socket.emit('refresh_data', {to : 'Admisi'})
                this.$socket.emit('refresh_data_all',{})
                return this.$swal({
                    icon: 'success',
                    title: 'Berhasil mengubah status antrean'
                }).then(()=>{
                    this.loadingOverlay = true
                    let data = {
                      type : 'add-new-reservasi' 
                    }

                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data:data}, 
                        "POST"
                    ).then(res=>{
                        this.loadingOverlay = false
                        let resp = res.data
                        resp.statusType = 200
            
                        this.$router.push({ name: 'RoReservasi', params: { pageSlug: resp.data.id} }).catch(()=>{})
                    })
                })
            })
        }
    },
    mounted(){
        this.sockets.subscribe('disp_refresh_unit', function(){
            if(!this.isPlay){
            this.apiGet()
            }
        })
        this.sockets.subscribe('disp_refresh_data_all', function(data) {
            if(!this.isPlay){
                this.apiGet()
            }
        })
        this.apiGet()
    },
    watch:{
        $route(){
            this.apiGet()
        },
        '$route.params.pageSlug':function(){
            this.$set(this,'filter',{})
        }
    },
    sockets: {
        connect() {
            this.isConnected = true
        },

        disconnect() {
            this.isConnected = false
        },

        messageChannel(data) {
            this.socketMessage = data
        },
    },
}
</script>